<template>
  <b-form>
    <b-card title="Ekle">
      <b-form-group
        label="Başlık"
        label-for="title"
      >
        <b-form-input
          id="title"
          v-model="document.title"
          placeholder="Başlık"
        />
      </b-form-group>
      <b-form-group
        label="Metin"
        label-for="content"
      >
        <b-form-textarea
          id="content"
          v-model="document.content"
          placeholder="Metin"
        />
      </b-form-group>
    </b-card>
    <b-card title="Dosya">
      <b-form-group
        label="Dosya Yükle"
        label-for="file"
      >
        <b-form-file
          id="file"
          v-model="document.upload_doc"
          placeholder="Bir dosya seçin veya buraya bırakın..."
          drop-placeholder="Dosyayı buraya bırakın..."
          browse-text="Dosya Seçin"
        />
      </b-form-group>
    </b-card>
    <b-button
      variant="danger"
      :disabled="submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </b-form>
</template>

<script>
import {
  BForm,
  BCard,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BFormGroup,
  BButton,
} from 'bootstrap-vue'

export default {
  name: 'Add',
  components: {
    BForm,
    BCard,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BFormGroup,
    BButton,
  },
  data() {
    return {
      submitStatus: false,
    }
  },
  computed: {
    document() {
      return this.$store.getters['documents/getDocument']
    },
    saveStatus() {
      return this.$store.getters['documents/getDocumentSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.$store.dispatch('documents/documentReset')
  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.document.submitStatus = true
      this.$store.dispatch('documents/documentSave', this.document)
    },
  },
}
</script>
